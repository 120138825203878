<template>
  <div class="body">
    <div id="app">
      <section ref="topHeader" style="
          top: 0;
          width: 100%;
          max-width: 100%;
          z-index: 1000;
          background-color: var(--darkBackground);
        " class="top-header">
        <a href="#" class="deposit-link">
          <div class="row download-app d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-right">
              <i class="bi bi-android2" style="
                  color: #f6d200;
                  margin-left: 3px;
                  margin-right: 10px;
                  font-weight: bold;
                  font-size: 35px;
                  cursor: pointer;
                "></i>
              <div style="margin-right: 1px" class="d-flex flex-column">
                <strong class="mt-2">Download App!</strong>
                <strong style="color: #f4df36">Get Ksh.50 Bonus!</strong>
              </div>
            </div>

            <div class="d-flex">
              <img style="width: 30px; " src="/img/icons/bb-icons/download-app-v1.svg" />
              <div style="margin-right: 10px" class="d-flex flex-column">
                <strong>Free</strong>
                <strong>Download</strong>
              </div>
              <img style="width: 40px;border-radius: 8px;" src="/bahatiBanner.png" />
            </div>
          </div>
        </a>

        <div class="d-flex m-0 align-items-stretch header"
          style="background-color: var(--darkBackground); max-width: 568px; width: 100%;"
          v-show="current_page != 'deposit' && current_page != 'casinogames'">

          <div class="d-flex align-items-center px-0 pt-2 pb-1 m-0 text-center logo"
            style="flex: 0 0 auto; height: 100%;">
            <router-link to="/">
              <img style="max-width: 100px; max-height: 100%; padding: 6px;margin-top: -8px;" src="/bahatiLogo.png" />
            </router-link>
          </div>

          <div class="p-0 login" style="flex: 1; height: 100%; max-width: 468px; overflow: hidden;">
            <div v-if="profile" class="d-flex depo text-center justify-content-around mt-2" style="align-items:center;">
              <router-link class="ml-2" to="/search"
                style="background-color: var(--yellow);border: none;color: #02032f;font-weight: bold;border-radius: 2px;width: 30px;height: 30px;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                <i class="bi bi-search" style="color: var(--darkerBackground)"></i>
              </router-link>

              <router-link to="/deposit" style="
                  background-color: var(--yellow);
                  border: none;
                  color: #02032f;
                  font-weight: bold;
                  border-radius: 2px;
                  width: 70px;
                  height: 30px;
                  padding-top: 5px;
                  cursor: pointer;
                ">
                DEPOSIT
              </router-link>

              <router-link to="/my-account">
                <span style="color: var(--yellow);" class="wallet-balance wallet-text">
                  <strong>Ksh. <span v-text="bal"></span></strong>
                </span>
              </router-link>

              <a class="" @click="showMenu">
                <img style="width: 24px" src="/img/icon/icon-menu.png" />
              </a>
            </div>

            <div v-else class="d-flex text-center d-none justify-content-between align-items-center mt-2"
              style="width: 100%">
              <router-link class="ml-2" to="/search"
                style="background-color: var(--yellow);border: none;color: #02032f;font-weight: bold;border-radius: 2px;width: 30px;height: 30px;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                <i class="bi bi-search" style="color: var(--darkerBackground)"></i>
              </router-link>


              <router-link to="/login" class="header-login-button"
                style="background-color: red; border: none; border-radius: 3px;width: 70px;">
                Login
              </router-link>

              <!-- <router-link to="/join" class="header-join-button" style="color: #000 !important;">
                Join
              </router-link> -->
              <router-link to="/join" class="header-join-button" style="
                  background-color: var(--yellow) !important;
                  color: black;
                  width: 80px;
                  font-weight: 600;
                  border-radius: 3px;
                  margin-right: 20px;
                ">
                Register
              </router-link>

              <a class="mr-2" @click="showMenu">
                <img style="width: 24px;margin-right: 5px;" src="/img/icon/icon-menu.png" />
              </a>
            </div>

            <section
              style="position: relative;z-index: 500;max-width: 100%;background-color: #02032f;border-top-left-radius: 6px;border-bottom-left-radius: 6px;border: solid var(--light-gray) 0.0001rem;"
              class="feature-menu mt-1">

              <!-- Fixed More item -->
              <div
                style="position: absolute;top: 0;right: 0;background-color: var(--darkBackground);padding: 10px;z-index: 1000;margin-top: -4px;"
                class="feature-icon-item-wrapper text-center" @click="toggleOverlay">
                <a>
                  <div class="icon-container">
                    <img style="width: 18px; height: 18px; margin-top: 2px;" src="/img/icon/icons8-more.png"
                      class="menu-icon-svg" />
                  </div>
                  <span style="color: var(--yellow);font-weight: bold;">More</span>
                </a>
              </div>

              <div style="
              background-color: #02032f;
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
              max-width: 110%;
              color: white;
              display: flex;
              justify-content: space-between;
              overflow-x: auto;
              overflow-y: hidden;
              white-space: nowrap; 
              scrollbar-width: none;
              -ms-overflow-style: none;
            " class="icons-wrapper top-menu-items">

                <div style="display: flex;">
                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/">
                      <div class="icon-container">
                        <img src="/img/icons/bb-icons/soccer.svg" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/') }">Soccer</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/crash">
                      <div class="icon-container">
                        <img src="/img/icons/bb-icons/crash-games.svg" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/crash') }">Crash</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/virtuals">
                      <div class="icon-container">
                        <img src="/img/icons/bb-icons/virtuals.svg" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/virtuals') }">Virtuals</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/casino">
                      <div class="icon-container">
                        <img src="/img/icons/bb-icons/casino-v1.svg" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/casino') }">Casino</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/jackpot-games">
                      <div class="icon-container">
                        <img src="/img/icons/bb-icons/BB-jackpot.svg" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/jackpot-games') }">Jackpot</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/sportsbook">
                      <div class="icon-container">
                        <img src="/img/TopNav/play-icon.png" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/sportsbook') }">Ligi Moto</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/gifts">
                      <div class="icon-container">
                        <img src="/img/icons/bb-icons/promotion.svg" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/gifts') }">Promos</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/aviator">
                      <div class="icon-container">
                        <img src="/img/icons/bb-icons/aviator-icon.svg" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/aviator') }">Aviator</span>
                    </router-link>
                  </div>

                  <div style="margin-right: -15px; min-width: 70px;" class="feature-icon-item-wrapper text-center">
                    <router-link style="color: white;" to="/live">
                      <div class="icon-container">
                        <img src="/img/TopNav/play-icon.png" class="menu-icon-sports-svg" />
                      </div>
                      <span :class="{ 'text-yellow': isActive('/live') }">Live</span>
                    </router-link>
                  </div>


                </div>

              </div>
            </section>

          </div>
        </div>
      </section>

      <transition name="fade">
        <div v-if="showOverlay" class="mainoverlay">
          <div class="overlay-content">
            <section ref="topHeader"
              style="top: 0;width: 100%;max-width: 100%;z-index: 1000;background-color: var(--darkBackground);"
              class="top-header">
              <a @click="toggleOverlay" href="#" class="deposit-link">
                <div class="row download-app d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-right">
                    <i class="bi bi-android2" style="
                  color: #f6d200;
                  margin-left: 3px;
                  margin-right: 10px;
                  font-weight: bold;
                  font-size: 35px;
                  cursor: pointer;
                "></i>
                    <div style="margin-right: 1px" class="d-flex flex-column">
                      <strong class="mt-2">Download App!</strong>
                      <strong style="color: #f4df36">Get Ksh.50 Bonus!</strong>
                    </div>
                  </div>

                  <div class="d-flex">
                    <img style="width: 30px; " src="/img/icons/bb-icons/download-app-v1.svg" />
                    <div style="margin-right: 10px" class="d-flex flex-column">
                      <strong>Free</strong>
                      <strong>Download</strong>
                    </div>
                    <img style="width: 40px;border-radius: 8px;" src="/bahatiBanner.png" />
                  </div>
                </div>
              </a>

              <div class="d-flex m-0 align-items-stretch header"
                style="background-color: var(--darkBackground); max-width: 568px; width: 100%;"
                v-show="current_page != 'deposit' && current_page != 'casinogames'">

                <div @click="toggleOverlay" class="d-flex align-items-center px-0 pt-2 pb-1 m-0 text-center logo"
                  style="flex: 0 0 auto; height: 100%;">
                  <router-link to="/">
                    <img style="max-width: 100px; max-height: 100%; padding: 6px;margin-top: -8px;"
                      src="/bahatiLogo.png" />
                  </router-link>
                </div>

                <div class="p-0 login" style="flex: 1; height: 100%; max-width: 468px; overflow: hidden;">
                  <div @click="toggleOverlay" v-if="profile" class="d-flex depo text-center justify-content-around mt-2"
                    style="align-items:center;">
                    <router-link class="ml-2" to="/search"
                      style="background-color: var(--yellow);border: none;color: #02032f;font-weight: bold;border-radius: 2px;width: 30px;height: 30px;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                      <i class="bi bi-search" style="color: var(--darkerBackground)"></i>
                    </router-link>

                    <router-link to="/deposit"
                      style="background-color: var(--yellow);border: none;color: #02032f;font-weight: bold;border-radius: 2px;width: 70px;height: 30px;padding-top: 5px;cursor: pointer;">
                      DEPOSIT
                    </router-link>

                    <router-link to="/my-account">
                      <span style="color: var(--yellow);" class="wallet-balance wallet-text">
                        <strong>Ksh. <span v-text="bal"></span></strong>
                      </span>
                    </router-link>

                    <a class="" @click="showMenu">
                      <img style="width: 24px" src="/img/icon/icon-menu.png" />
                    </a>
                  </div>

                  <div @click="toggleOverlay" v-else
                    class="d-flex text-center d-none justify-content-between align-items-center mt-2"
                    style="width: 100%">
                    <router-link class="ml-2" to="/search"
                      style="background-color: var(--yellow);border: none;color: #02032f;font-weight: bold;border-radius: 2px;width: 30px;height: 30px;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                      <i class="bi bi-search" style="color: var(--darkerBackground)"></i>
                    </router-link>


                    <router-link to="/login" class="header-login-button"
                      style="background-color: red; border: none; border-radius: 3px;width: 70px;">
                      Login
                    </router-link>

                    <router-link to="/join" class="header-join-button"
                      style="background-color: var(--yellow) !important;color: black;width: 80px;font-weight: 600;border-radius: 3px;margin-right: 20px;">
                      Register
                    </router-link>

                    <a class="mr-2" @click="showMenu">
                      <img style="width: 24px;margin-right: 5px;" src="/img/icon/icon-menu.png" />
                    </a>
                  </div>

                  <section
                    style="position: relative;z-index: 500;max-width: 100%;background-color: #02032f;border-top-left-radius: 6px;border-bottom-left-radius: 6px;border: solid var(--light-gray) 0.0001rem;"
                    class="feature-menu mt-1">

                    <!-- Fixed More item -->
                    <div
                      style="position: absolute;top: 0;right: 0;background-color: var(--darkBackground);padding: 10px;z-index: 1000;margin-top: -4px;"
                      class="feature-icon-item-wrapper text-center" @click="toggleOverlay">
                      <a>
                        <div class="icon-container">
                          <img style="width: 18px; height: 18px; margin-top: 2px;" src="/img/icon/icon-close.png"
                            class="menu-icon-svg" />
                        </div>
                        <span style="color: var(--yellow);font-weight: bold;">Less</span>
                      </a>
                    </div>

                    <div
                      style="background-color: #02032f;border-top-left-radius: 6px;border-bottom-left-radius: 6px;max-width: 110%;color: white;display: flex;justify-content: space-between;overflow-x: auto;overflow-y: hidden;white-space: nowrap; scrollbar-width: none;-ms-overflow-style: none;"
                      class="icons-wrapper top-menu-items">

                      <div @click="toggleOverlay" style="display: flex;">
                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/">
                            <div class="icon-container">
                              <img src="/img/icons/bb-icons/soccer.svg" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/') }">Soccer</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/crash">
                            <div class="icon-container">
                              <img src="/img/icons/bb-icons/crash-games.svg" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/crash') }">Crash</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/virtuals">
                            <div class="icon-container">
                              <img src="/img/icons/bb-icons/virtuals.svg" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/virtuals') }">Virtuals</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/casino">
                            <div class="icon-container">
                              <img src="/img/icons/bb-icons/casino-v1.svg" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/casino') }">Casino</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/jackpot-games">
                            <div class="icon-container">
                              <img src="/img/icons/bb-icons/BB-jackpot.svg" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/jackpot-games') }">Jackpot</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/sportsbook">
                            <div class="icon-container">
                              <img src="/img/TopNav/play-icon.png" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/sportsbook') }">Ligi Moto</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/gifts">
                            <div class="icon-container">
                              <img src="/img/icons/bb-icons/promotion.svg" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/gifts') }">Promos</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/aviator">
                            <div class="icon-container">
                              <img src="/img/icons/bb-icons/aviator-icon.svg" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/aviator') }">Aviator</span>
                          </router-link>
                        </div>

                        <div style="margin-right: -15px; min-width: 70px;"
                          class="feature-icon-item-wrapper text-center">
                          <router-link style="color: white;" to="/live">
                            <div class="icon-container">
                              <img src="/img/TopNav/play-icon.png" class="menu-icon-sports-svg" />
                            </div>
                            <span :class="{ 'text-yellow': isActive('/live') }">Live</span>
                          </router-link>
                        </div>


                      </div>

                    </div>
                  </section>

                </div>
              </div>
            </section>

            <section style="position: relative;z-index: 500;" class="p0 pb-3">
              <br />
              <div @click="toggleOverlay" class="icons-wrapper-menu top-menu-items d-flex"
                style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;border-radius: 10px;margin-bottom: 10px;">
                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/soccer.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/') }">Soccer</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/crash">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/crash-games.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/crash') }">Crash</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/casino">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/casino-v1.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/casino') }">Casino</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/jackpot-games">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/BB-jackpot.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/jackpot-games') }">Jackpot</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/sportsbook">
                    <div class="icon-container">
                      <img src="/img/TopNav/play-icon.png" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/sportsbook') }">Ligi Moto</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/aviator">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/aviator-icon.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/aviator') }">Aviator</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <a style="color: white;" href="#">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/download-app-v1.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span>App</span>
                  </a>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/gifts">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/promotion.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/gifts') }">Promos</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link style="color: white;" to="/virtuals">
                    <div class="icon-container">
                      <img src="/img/icons/bb-icons/virtuals.svg" class="menu-icon-sports-svg" />
                    </div>
                    <span :class="{ 'text-yellow': isActive('/virtuals') }">Virtuals</span>
                  </router-link>
                </div>

              </div>

            </section>

            <section @click="toggleOverlay" style="position: relative;z-index: 500;" class="p0 pb-3">
              <div @click="toggleOverlay" class="icons-wrapper-menu top-menu-items d-flex"
                style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;border-radius: 10px;margin-bottom: 10px;">
                <div v-for="sport in sportsList" :key="sport.id"
                  class="feature-icon-item-wrapper text-center sport-item">
                  <div @click="setSportMainMenu(sport.id, sport.name)">
                    <div class="icon-container-darker">
                      <img :src="sport.icon" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">{{ sport.displayName }}</span>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </transition>

      <div id="menu-modal" class="faso-menu-modal" style="z-index: 99999;">
        <div class="faso-menu-modal-content">
          <section class="w-100 menu-bg">
            <div class="row m-0 p-0 side-menu-header">
              <div>
                <div class="p-3 px-3">
                  <strong class="text-light faso-menu-close" style="cursor: pointer">
                    <img style="width: 25px" src="/img/icon/icon-close.png" alt="qwerty close menu" />
                  </strong>
                </div>
              </div>
              <div>
                <p style="
                    margin-top: 5px;
                    font-weight: bold;
                    color: white;
                    font-size: 23px;
                    cursor: pointer;
                  " @click="goTo('home')">
                  <img style="width: 180px;margin-bottom: -16px;" src="/longLogo.png" @click="goTo('home')">
                </p>
              </div>
              <div class="text-right">
                <div v-if="profile" class="d-flex depo text-center justify-content-around">
                  <a @click="goTo('my-account')">
                    <i class="bi bi-wallet2" style="color: var(--yellow); font-size: 21px; font-weight: bold"></i>
                    <br />
                    <span class="wallet-balance text-yellow wallet-text">
                      <strong>Ksh<span v-text="bal"></span></strong>
                    </span>
                  </a>
                </div>

                <div v-else class="d-flex text-center pr-2 pt-1">
                  <router-link to="/login" @click.native="closeMenu" class="header-join-button small-text"
                    style="color: #000 !important; margin-top: 0px">
                    Login
                  </router-link>
                </div>
              </div>
            </div>
            <!-- <hr class="m-1" /> -->
            <div
              style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;border-radius: 10px;margin-bottom: 10px;margin-top: 10px;">

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div mt-2">
                <router-link :class="{ 'active-sidebar': isActive('/live') }" to="/live" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/live-games.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Live</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/virtuals') }" to="/virtuals"
                  @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/virtuals.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Virtuals</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/casino') }" to="/casino" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/casino-v1.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Casino</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/jackpot-games') }" to="/jackpot-games" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/BB-jackpot.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Jackpot</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/print') }" to="/print" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/printer.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Print</span>
                </router-link>
              </div>
            </div>

            <!-- <hr class="mt-3 mb-3" /> -->

            <div
              style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;border-radius: 10px;margin-bottom: 10px;">
              <div class="d-flex justify-content-left px-3 py-1 flatlink-div mt-2">
                <router-link :class="{ 'active-sidebar': isActive('/deposit') }" to="/deposit" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/deposit.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Deposit</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/my-account') }" to="/my-account"
                  @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/withdraw.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Withdraw</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/my-profile-bets') }" to="/my-profile-bets"
                  @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/bet-history.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Bet history</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/my-account') }" to="/my-account"
                  @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/transaction-history.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Transaction history</span>
                </router-link>
              </div>
            </div>

            <!-- <hr class="mt-3 mb-3" /> -->

            <div
              style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;border-radius: 10px;margin-bottom: 10px;">
              <div class="d-flex justify-content-left px-3 py-1 flatlink-div mt-2">
                <router-link :class="{ 'active-sidebar': isActive('/responsible-gaming') }" to="/responsible-gaming"
                  @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-18-plus.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Responsible Gaming</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/faq') }" to="/faq" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-faq.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">F.A.Q</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/terms') }" to="/terms" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-terms.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Terms & Conditions</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/blogs') }" to="/blogs" @click.native="closeMenu"
                  class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-article.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Blogs</span>
                </router-link>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <router-link :class="{ 'active-sidebar': isActive('/sportsbook') }" to="/sportsbook"
                  @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/soccer.svg" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Sports</span>
                </router-link>
              </div>

            </div>


            <!-- <hr class="mt-3 mb-3" /> -->

            <div style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;border-radius: 10px;">

              <div v-for="(social,index) in smh" :key="index" 
               style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;margin-bottom: 10px;">
              <div class="d-flex justify-content-left px-3 py-1 flatlink-div mt-2">
                <a :href="social.link" @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img :src="getIconUrl(social.icon)" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">{{social.name}}</span>
                </a>
              </div>
             <!--  <div
              style="background-color: var(--darkerBackground);padding: 3px;width: 96%;margin-right: 2%;margin-left: 2%;border-radius: 10px;margin-bottom: 10px;">
              <div class="d-flex justify-content-left px-3 py-1 flatlink-div mt-2">
                <a href="#" @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-facebook.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Facebook</span>
                </a>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <a href="#" @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-twitterx.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">x.com</span>
                </a>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <a href="#" @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-instagram.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Instagram</span>
                </a>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <a href="#" @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-tiktok.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Tiktok</span>
                </a>
              </div>

              <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
                <a href="#" @click.native="closeMenu" class="flat-link" style="color: var(--white);">
                  <img src="/img/icons/bb-icons/icons-whatsapp.png" class="side-icon-svg mr-2" />
                  <span style="width: 100%;">Whatsapp</span>
                </a>
              </div> -->
            </div>

            </div>


            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="logout" class="flat-link">
                <img src="/menu/logout.png" class="side-icon-svg mr-2" />
                <span class="text-danger">Logout</span>
              </a>
            </div>

          </section>
        </div>
      </div>

      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>

      <router-view></router-view>

      <div class="depo-widget d-none" id="dpo-widg" v-show="current_page != 'deposit'">
        <span id="dpo-close" class="text-light" @click="closeWidget()"><i class="bi bi-x-lg"></i></span>
        <router-link to="/deposit" class="deposit-link" v-show="current_page != 'deposit'">
          <img src="/img/depo.webp" class="depo-widg" />
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
//import mqtt from "mqtt";
import Footer from "./components/Footer.vue";
import axios from "@/services/api";
import store from "./store/store";
import cms from "@/services/cms";


export default {
  store,
  name: "app",
  data: function () {
    return {
      content: "",
      smh: [],
      contacts: {},
      current_menu: "",
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: "",
      message: "Please enter amount you want to deposit",
      aviatorGame: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
      ],
      isWebView: false,
      referralCode: null,
      homePageGame: [
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
          crashGameUrl: "jetx",
        },
      ],
      showOverlay: false,
      selectedSport: null,
      sportsList: [
        { id: 1, name: 'Soccer', displayName: 'Football', icon: '/img/icons/bb-icons/soccer.svg' },
        { id: 2, name: 'Basketball', displayName: 'Basketball', icon: '/img/icons/bb-icons/basketball.svg' },
        { id: 21, name: 'Cricket', displayName: 'Cricket', icon: '/img/icons/bb-icons/cricket.svg' },
        { id: 5, name: 'Tennis', displayName: 'Tennis', icon: '/img/icons/bb-icons/tennis-ball.svg' },
        { id: 4, name: 'IceHockey', displayName: 'Ice Hockey', icon: '/img/icons/bb-icons/ice-hockey.svg' },
        { id: 16, name: 'AmericanFootball', displayName: 'A. Football', icon: '/img/icons/bb-icons/american-football.svg' },
        { id: 10, name: 'Boxing', displayName: 'Boxing', icon: '/img/icons/bb-icons/boxing-glove.svg' },
        { id: 31, name: 'Badminton', displayName: 'Badminton', icon: '/menu/badminton.png' },
        { id: 12, name: 'Rugby', displayName: 'Rugby', icon: '/img/icons/bb-icons/rugby.svg' },
        { id: 23, name: 'Volleyball', displayName: 'Volleyball', icon: '/img/icons/bb-icons/volleyball.svg' },
        { id: 6, name: 'Handball', displayName: 'Handball', icon: '/menu/handball.png' },
        { id: 117, name: 'MixedMartialArts', displayName: 'M.M.A', icon: '/menu/Mma.png' },
        { id: 22, name: 'Darts', displayName: 'Darts', icon: '/menu/Darts.png' }
      ],
    };
  },
  components: {
    Footer,
  },
  mounted: function () {

    this.getLicence();
    this.getContacts();
    this.getSmh();

    var vm = this;

    vm.myProfile = vm.getProfile();

    this.checkIfWebView();

    this.initMenuModal();
    this.getSports();
    setInterval(function () {
      vm.myProfile = vm.getProfile();
    }, 2000);
    this.EventBus.$on("deposit:popup", function (payload) {
      vm.amount = payload.amount;
      vm.message = payload.message;
      if (document.getElementById("deposit-init") != null) {
        document.getElementById("deposit-init").click();
      }
    });

    this.getTopHeaderHeight();

    this.EventBus.$on("init:mqtt", function () {
      console.log("Wants Inititalize MQTT");

      if (vm.mqttClient !== false) {
        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();
    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : "";
    var utm_medium = params.utm_medium ? params.utm_medium : "";
    var utm_campaign = params.utm_campaign ? params.utm_campaign : "";
    var referral_code = params.p ? params.p : "";
    var referrer = document.referrer;
    var btag = params.btag ? params.btag : "";
    console.log("GOT referrer " + referrer);

    if (btag.length > 0) {
      this.setValue("btag", btag);
    }

    if (utm_source.length > 0) {
      this.setValue("utm_source", utm_source);
    }

    if (utm_medium.length > 0) {
      this.setValue("utm_medium", utm_medium);
    }

    if (referral_code.length > 0) {
      this.setValue("referral_code", referral_code);
    }

    if (utm_campaign.length > 0) {
      this.setValue("utm_campaign", utm_campaign);
    }
    this.setValue("referrer", referrer);

    if (this.$refs.topHeader) {
      window.addEventListener("resize", this.getTopHeaderHeight);
    }
  },

  unmounted: function () {
    window.removeEventListener("resize", this.getTopHeaderHeight);
  },

  computed: {
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return "";
      } else {
        return "d-none";
      }
    },
    bal: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    full_name: function () {
      if (this.profile === undefined) {
        return "";
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {
        return ms;
      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + " " + mn + " " + ln + " - " + ms;
    },
    profile: function () {
      return this.myProfile;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
    homePageFontColor: function () {
      if (this.current_page === "home") {
        return "yellow-txt";
      }

      return "";
    },
    livePageFontColor: function () {
      if (this.current_page === "live") {
        return "yellow-txt";
      }

      return "";
    },
    historyPageFontColor: function () {
      if (this.current_page === "history") {
        return "yellow-txt";
      }

      return "";
    },
    betslip: function () {
      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip;
    },
    activeBets: function () {
      var p = this.getProfile();

      if (!p) {
        return 0;
      }

      return p.b;
    },
    betslip_count: function () {
      if (!this.betslip.total || this.betslip.total == "") {
        return 0;
      }

      return parseInt(this.betslip.total);
    },
  },
  methods: {
    async getContacts() {
      try {
        const response = await cms.get("/get/conatcts");
        if (response.status == 200) {
          this.contacts = response.data;
        }
      } catch (error) {
        console.log("An error occurred while fetching contacts", error);
      }
    },
    getIconUrl(url) {
      if (url && !url.startsWith("https://")) {
        return `https://${url}`;
      }
      return url;
    },
    async getLicence() {
      try {
        const response = await cms.get("/get/license", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.content = response.data.content;
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching responsible gamming",
          error.response?.data || error.message
        );
      }
    },
    async getSmh() {
      try {
        const response = await cms.get("/get/smhandles", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.smh = response.data.socialMedia;
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching social media handles",
          error.response?.data || error.message
        );
      }
    },


    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },

    setSportMainMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.selectedSport = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },
    checkIfWebView() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const isWebView =
        /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
        /\bwv\b/.test(userAgent) ||
        /Android.*Version\/[\d.]+.*(?:Chrome\/[\d.]+ Mobile Safari\/[\d.]+|wv)/i.test(
          userAgent
        );

      this.isWebView = isWebView;

      if (isWebView) {
        console.log("User is using a WebView.");
      } else {
        console.log("User is using a standard browser.");
      }
    },
    closeMenu() {
      document.getElementById("menu-modal").style.display = "none";
    },

    getReferrer() {
      return document.referrer || "No referrer found";
    },

    canStick: function () {
      if (
        this.$store.state.current_page == "casinogames" ||
        this.$store.state.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "header-stick";
      }
    },
    canFeatureStick: function () {
      if (
        this.$store.state.current_page == "match" ||
        this.$store.state.current_page == "casinogames" ||
        this.$store.state.current_page == "casino" ||
        this.$store.state.current_page == "casino-live" ||
        this.$store.state.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "feature-stick";
      }
    },
    isActive(route) {
      return this.$route.path === route;
    },

    setActive: function (menu) {
      this.current_menu = menu;
    },
    livescore: function () { },

    initMqtt: function () {
      /*
      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;


      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      console.log('at endpoint ' + endpoint)

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {

        console.log('connected here')

        if (vm.myProfile) {

          var profileTopic = 'topic/profile/' + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();

          client.subscribe(profileTopic, function (err) {

            if (!err) {

              console.log('subscribed to topic ' + profileTopic);

            }

          });
        }

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

       */
    },

    getMarket: function (sport_id) {
      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {
      this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id);
      this.$router.push({ name: "sport", params: {} });
    },

    initMenuModal: function () {
      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = "";
      };

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("menu-modal")) {
          modal.style.display = "none";
        }
      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },

    initDeposit: function () {
      this.dismiss();
      this.$router.push({ name: "deposit" });
    },
    dismiss: function () {
      console.log("DISMISS MENU");
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "sport-" + index + "-");
    },
    getSports: function () {
      this.$store.dispatch("getSports");
    },

    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
      this.deposit();
    },

    deposit: function () {
      this.reset();
      var p = this.getProfile();

      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });

        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 1) {
        this.$toast.open({
          message: "Enter at least Ksh. 10 or above",
          type: "error",
          position: "top",
        });
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer,
      };

      vm.loading = "loading";

      axios
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.message;
          vm.setSuccess("Deposit Initiated", msg);
          vm.message = "Please enter amount you want to deposit";
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 400 ||
              parseInt(err.response.status) === 428
            ) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });

              vm.logout();
              return;
            } else {
              this.$toast.open({
                message: err.response.data.message,
                type: "error",
                position: "top",
              });

              console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });

            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },

    showDeposit: function () {
      document.getElementById("deposit-init").click();
    },
    searchVisible: function () {
      console.log("this.current_page ==> " + this.current_page);

      var searchVisible = this.current_page !== "search";
      console.log(
        "this.current_page ==> " +
        this.current_page +
        " searchVisible ==> " +
        searchVisible
      );

      return searchVisible;
    },
    getTopHeaderHeight: function () {
      const height = this.$refs.topHeader?.clientHeight;

      //set root css variable
      document.documentElement.style.setProperty(
        "--top-header-height",
        (height ?? 0) + "px"
      );
    },
  },
  created() {
    this.$store.dispatch("fetchReferralCode");
  },
  beforeDestroy: function () {
    console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

html {
  display: grid;
  min-height: 100%;
}

.text-white {
  color: white;
}

.text-yellow {
  color: var(--yellow);
}

.active-sidebar {
  background-color: var(--yellow);
  color: black;
  width: 100%;
  padding: 5px;
  border-radius: 8px;
}


.icons-wrapper-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.sport-item {
  flex: 1;
  min-width: 60px;
  max-width: 90px;
  margin: 4px;
  text-align: center;
}

.menu-icon-sports-svg {
  max-width: 50px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  /* Allow vertical scrolling */
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  height: 100vh;
}



.mainoverlay {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 568px;
  width: 100%;
  height: 100vh;
  background-color: var(--darkBackground);
  z-index: 9999;
  display: block;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
}


.overlay-content {
  position: relative;
  max-width: 568px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in Vue 2 */
  {
  opacity: 0;
}


.close-overlay {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
}


#container {
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 100%;
}

h1 {
  font-size: 0.9em;
  font-weight: 100;
  letter-spacing: 3px;
  padding-top: 5px;
  color: #FCFCFC;
  /* $white */
  padding-bottom: 5px;
  text-transform: uppercase;
}

.green {
  color: black;
  /* darken($secondary, 20%) */
}

.red {
  color: #D6808C;
  /* darken($error, 10%) */
}

.alert {
  font-weight: 700;
  letter-spacing: 5px;
}

p {
  margin-top: -5px;
  font-size: 0.5em;
  font-weight: 100;
  color: #6F6F6F;
  /* darken($dark, 10%) */
  letter-spacing: 1px;
}

button,
.dot {
  cursor: pointer;
}

#success-box {
  position: absolute;
  width: 350px;
  height: 400px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10px;
  background: url('/app/qwertyAPPDOWNLOAD.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(137, 138, 141, 0.164);
  perspective: 40px;
}


#error-box {
  position: absolute;
  width: 35%;
  height: 100%;
  right: 12%;
  background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(203, 205, 211, 0.1);
}

.dot {
  position: absolute;
  top: 4%;
  right: 6%;
}

.close-button {
  position: absolute;
  top: -25px;
  right: -25px;
  width: 19px;
  cursor: pointer;
  z-index: 100;
  /* Ensure it stays on top */
}

.two {
  right: 12%;
  opacity: 0.5;
}

.face,
.face2 {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #FCFCFC;
  /* $white */
  border-radius: 50%;
  border: 1px solid #777777;
  /* $dark */
  top: 21%;
  left: 37.5%;
  z-index: 2;
}

.face {
  animation: bounce 1s ease-in infinite;
}

.face2 {
  animation: roll 3s ease-in-out infinite;
}

.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  /* $dark */
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right {
  left: 68%;
}

.mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  /* $dark */
  transform: rotate(45deg);
}

.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  /* $dark */
  transform: rotate(45deg);
}

.shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: 0.5;
  background: #777777;
  /* $dark */
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}

.scale {
  animation: scale 1s ease-in infinite;
}

.move {
  animation: move 3s ease-in-out infinite;
}

.message {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 47%;
}

.button-box {
  position: absolute;
  color: black;
  top: 83%;
  left: 30%;
  outline: 0;
  border: none;
  transition: all 0.5s ease-in-out;
}

.button-box:hover {
  background: #F5F5F5;
  /* darken($white, 5%) */
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }

  50% {
    left: 60%;
    transform: rotate(168deg);
  }

  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: 25%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: add a semi-transparent background */
  z-index: 9999;
  /* Set a high z-index for the overlay */
}

.card {
  width: 300px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  /* Make sure the card has a white background */
  border-radius: 5px;
  /* Optional: add some border radius for aesthetics */
  z-index: 10000;
  /* Set a higher z-index for the card */
  position: relative;
  /* Make sure z-index is applied correctly */
}


.crash-icon-container {
  background-color: #23313D;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.logo {
  flex-grow: 1;
  gap: 1rem
}

.top-header {
  position: sticky;
}
</style>
